import { Image, Stack, Text } from '@chakra-ui/react'
import File from '../../assets/images/file.png'

export default function CustomFile({ name }) {
    return (
        <Stack
            boxShadow={'0px 0px 25px -14px #000'}
            w={{base: '100%', lg: '48%'}}
            borderRadius={'15px'}
            direction={'row'}
            alignItems={'center'}
            gap={2}
            p={4}
            mb={'25px !important'}
            _hover={{cursor: 'pointer'}}
        >
            <Image src={File} />
            <Text fontFamily={'Poppins'}>{name ?? 'Loading...'}</Text>
        </Stack>
    )
}
