import { Stack, Image, Heading, Text } from '@chakra-ui/react'

export default function ShopCard({ shopPic, shopName, price, caption }) {
    return (
        <Stack
            boxShadow={'0px 0px 25px -14px #000'}
            borderRadius={12}
            w={{base: '100%', lg: '32%'}}
            px={'15px'}
            py={'20px'}
        >
            <Image src={shopPic} />
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                pt={'8px'}
                pb={'15px'}
            >
                <Heading fontSize={22} fontFamily={'Poppins'} >{shopName}</Heading>
                <Text fontFamily={'Poppins'} color={'primaryBlue.100'} fontWeight={'600'}>{price}</Text>
            </Stack>
            <Text fontSize={'16'} fontFamily={'Poppins'}>{caption}</Text>
        </Stack>
    )
}