import { Box, Button, Heading, Icon, Image, MenuButton, Stack, Text, Menu, MenuList, MenuItem, Link } from '@chakra-ui/react'
import { BsThreeDots } from 'react-icons/bs'
import { Link as ReactLink } from 'react-router-dom'

export default function FeedBox({ profile, name, time, caption, image, id, scatchFabUrl }) {
    return (
        <Stack
            marginBottom={'30px !important'}
            borderRadius={'12px'}
            px={{ base: '10px', lg: '25px' }}
            py={'20px'}
            border={'1px solid #e1e1e1'}
        >
            <Stack
                direction={'row'}
                alignItems={'flex-start'}
                justifyContent={'space-between'}
            >
                <Link
                    as={ReactLink}
                    to={`/profile/${id}`}
                    _hover={{
                        textDecoration: 'none'
                    }}
                >
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        gap={4}
                        mb={'15px'}
                    >
                        <Image src={profile} border={'4px solid'} borderColor={'primaryBlue.100'} borderRadius={'100%'} />
                        <Box>
                            <Heading fontFamily={'Poppins'} fontSize={'24px'} fontWeight={'700'}>{name}</Heading>
                            <Text fontFamily={'Poppins'} fontSize={'14px'}>{time}</Text>
                        </Box>
                    </Box>
                </Link>
                <Menu>
                    <Button
                        as={MenuButton}
                        bgColor={'transparent'}
                        _hover={{ bgColor: 'transparent' }}
                        _focus={{ bgColor: 'transparent' }}
                    >
                        <Icon as={BsThreeDots} fontSize={28} />
                    </Button>
                    <MenuList>
                        <MenuItem>Add to Collection</MenuItem>
                        <MenuItem>Report</MenuItem>
                        <MenuItem>Share</MenuItem>
                    </MenuList>
                </Menu>
            </Stack>
            <Text fontFamily={'Poppins'} fontSize={{ base: '14px', lg: '18px' }} pb={3}>{caption}</Text>
            {
                image &&
                <Image src={image} />
            }
            {
                scatchFabUrl &&
                <div className="sketchfab-embed-wrapper">
                    <iframe
                        frameBorder="0"
                        allowFullScreen
                        allow="autoplay; fullscreen; xr-spatial-tracking"
                        src={scatchFabUrl}
                        style={{ width: '100%', height: '569px' }}
                    >
                    </iframe>
                </div>
            }
        </Stack>
    )
}
