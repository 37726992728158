import {
    Stack,
    Container,
    FormControl,
    FormLabel,
    Input,
    Button,
    Heading,
    Box,
    Text,
    Image,
    Link
} from "@chakra-ui/react";
import { useRef } from 'react'
import Image1 from '../assets/images/1.png'
import Image2 from '../assets/images/2.png'
import { Link as ReactLink } from 'react-router-dom'

export default function SignIn() {

    const formLabelCss = {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '16px',
        ml: '10px',
    }

    const inputCss = {
        px: 3,
        py: 6,
        bgColor: '#f3f3f3',
        borderRadius: '50px',
        placeholder: 'John Doe',
        border: 0,
    }

    return (
        <Container maxW={'6xl'}>
            <Stack
                boxShadow={'0px 0px 25px -14px #000'}
                px={'30px'}
                py={'40px'}
                mx={{base: '0%', lg: '20%'}}
                mb={'80px'}
                borderRadius={12}
                bgColor={'#fff'}
            >
                <Heading
                    fontSize={'28px'}
                    fontFamily={'Poppins'}
                    color={'#000'}
                    mb={'30px'}
                >Sign In</Heading>
                <Stack
                    direction={{base: 'column', lg: 'row'}}
                    flexWrap={'wrap'}
                    spacing={0}
                    justifyContent={'space-between'}
                >
                    <Box
                        w={'100%'}
                        mb={'20px !important'}
                    >
                        <FormLabel sx={formLabelCss}>Email</FormLabel>
                        <Input
                            sx={inputCss}
                            _placeholder={{ fontWeight: '600' }}
                            _focusVisible={{ outline: 'none' }}
                            type='email'
                        />
                    </Box>
                    <Box
                        w={'100%'}
                        mb={'20px !important'}
                    >
                        <FormLabel sx={formLabelCss}>Password</FormLabel>
                        <Input
                            sx={inputCss}
                            _placeholder={{ fontWeight: '600' }}
                            _focusVisible={{ outline: 'none' }}
                            type='password' />
                    </Box>
                    <Box
                        w={'100%'}
                        mb={'20px'}
                    >
                        <Button
                            fontFamily={"Poppins"}
                            bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                            _hover={{
                                bg: 'primaryBlue.100',
                                color: '#fff'
                            }}
                            w={'full'}
                            borderRadius={8}
                            py={6}
                        >Login</Button>
                    </Box>
                    <Text
                        textAlign={'center'}
                        pt={6}
                        w={'full'}
                    >
                        <Link
                            as={ReactLink}
                            to={'/sign-in'}
                            color={'#858585'}
                            fontWeight={'600'}
                            fontSize={'18px'}
                            _hover={{textDecoration: 'none'}}
                        >
                            Forgot Password
                        </Link>
                    </Text>
                </Stack>
            </Stack>
            <Image src={Image1} position={'absolute'} top={0} right={0} zIndex={'-1'} />
            <Image src={Image2} position={'absolute'} bottom={0} left={0} zIndex={'-1'} />
        </Container>
    );
}