import { Stack, Textarea, Box, FormLabel, Input, Text, Button } from '@chakra-ui/react'
import { useRef } from 'react'

export default function UploadEnvForm() {
    const formLabelCss = {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '16px',
        ml: '10px',
    }

    const inputCss = {
        px: 3,
        py: 6,
        bgColor: '#f3f3f3',
        borderRadius: '50px',
        placeholder: 'John Doe',
        border: 0,
    }

    const areaCss = {
        px: 3,
        py: 6,
        bgColor: '#f3f3f3',
        borderRadius: '25px',
        placeholder: 'John Doe',
        border: 0,
    }

    const envFilesRef = useRef(null);
    const landThumbnailRef = useRef(null);

    return (
        <Stack>
            <Box mb={'20px !important'}>
                <FormLabel sx={formLabelCss}>Environment Name</FormLabel>
                <Input
                    sx={inputCss}
                    _placeholder={{ fontWeight: '600' }}
                    _focusVisible={{ outline: 'none' }}
                    type='text'
                />
            </Box>
            <Box mb={'20px !important'}>
                <FormLabel sx={formLabelCss}>Description</FormLabel>
                <Textarea
                    sx={areaCss}
                    resize={'none'}
                    height={'140px'}
                    _placeholder={{ fontWeight: '600' }}
                    _focusVisible={{ outline: 'none' }}
                >

                </Textarea>
            </Box>
            <Box
                w={'100%'}
                mb={'40px !important'}
            >
                <FormLabel sx={formLabelCss}>Upload Environment Files</FormLabel>
                <Input
                    ref={envFilesRef}
                    display={'none'}
                    type='file'
                />
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDir={'column'}
                    bgColor={'#f3f3f3'}
                    border={'4px dashed'}
                    borderRadius={'25px'}
                    borderColor={'primaryBlue.100'}
                    py={'40px'}
                >
                    <Text
                        fontFamily={'Poppins'}
                        color={"primaryBlue.100"}
                        fontWeight={'600'}
                        fontSize={{base: '18', lg: '22'}}
                    >Drag and Drop your files here</Text>
                    <Text
                        fontFamily={'Poppins'}
                        color={"primaryBlue.100"}
                        fontWeight={'600'}
                        fontSize={'18'}
                        py={2}
                    >or</Text>
                    <Button
                        onClick={() => envFilesRef.current.click()}
                        fontFamily={"Poppins"}
                        bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                        px={14}
                        _hover={{
                            bg: 'primaryBlue.100',
                            color: '#fff'
                        }}
                    >Browse Files</Button>
                </Box>
            </Box>
            <Box
                w={'100%'}
                mb={'40px !important'}
            >
                <FormLabel sx={formLabelCss}>Upload Land Thumbnail</FormLabel>
                <Input
                    ref={landThumbnailRef}
                    display={'none'}
                    type='file'
                />
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDir={'column'}
                    bgColor={'#f3f3f3'}
                    border={'4px dashed'}
                    borderRadius={'25px'}
                    borderColor={'primaryBlue.100'}
                    py={'40px'}
                >
                    <Text
                        fontFamily={'Poppins'}
                        color={"primaryBlue.100"}
                        fontWeight={'600'}
                        fontSize={{base: '18', lg: '22'}}
                    >Drag and Drop your files here</Text>
                    <Text
                        fontFamily={'Poppins'}
                        color={"primaryBlue.100"}
                        fontWeight={'600'}
                        fontSize={'18'}
                        py={2}
                    >or</Text>
                    <Button
                        onClick={() => landThumbnailRef.current.click()}
                        fontFamily={"Poppins"}
                        bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                        px={14}
                        _hover={{
                            bg: 'primaryBlue.100',
                            color: '#fff'
                        }}
                    >Browse Files</Button>
                </Box>
            </Box>
            <Box
                w={'100%'}
                mb={'20px'}
            >
                <Button
                    fontFamily={"Poppins"}
                    bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                    _hover={{
                        bg: 'primaryBlue.100',
                        color: '#fff'
                    }}
                    w={'full'}
                    borderRadius={8}
                    py={6}
                >Save</Button>
            </Box>
        </Stack>
    )
}
