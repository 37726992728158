import { Heading, Stack, Link, Box, Text, Image } from "@chakra-ui/react";
import { Link as ReactLink } from 'react-router-dom'
import Nft1 from '../../assets/images/nft1.png'
import { useState } from 'react'

export default function DiscoverNft() {

    const [discoverNft, setDiscoverNft] = useState([
        {
            image: Nft1,
            title: 'Collection 1',
        },  
        {
            image: Nft1,
            title: 'Collection 2',
        },  
        {
            image: Nft1,
            title: 'Collection 3',
        },  
        {
            image: Nft1,
            title: 'Collection 4x',
        },  
    ]);

    return (
        <Stack
            boxShadow={'0px 0px 25px -14px #000'}
            borderRadius={12}
            py={'20px'}
            px={'15px'}
        >
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={"space-between"}
                fontFamily={'Poppins'}
                mb={'20px'}
            >
                <Heading
                    fontSize={'24px'}
                    fontWeight={'700'}
                    fontFamily={'Poppins'}
                >
                    Discover NFTs</Heading>
            </Stack>
            <Stack>
                {
                    discoverNft.length &&
                    discoverNft?.map((v, i) => {
                        return (
                            <Box
                                key={i}
                                marginBottom={'10px !important'}
                                position={'relative'}
                                borderRadius={12}
                                overflow={'hidden'}
                            >
                                <Image src={v?.image} alt="loc image" marginRight={'20px'} flex={1} />
                                <Box 
                                    position={'absolute'}
                                    top={0}
                                    left={0}
                                    width={'100%'}
                                    h={'100%'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    bgColor={'#000000ab'}
                                    textAlign={'center'}
                                    color={'#fff'}
                                >
                                    <Heading fontSize={'22px'} fontFamily={'Poppins'} flex={1}>{v?.title}</Heading>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Stack>
        </Stack>
    );
}