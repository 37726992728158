import { Box, Container, Heading, Image, Stack, Text, Link, Button } from "@chakra-ui/react";
import MyLand from "../components/Home/MyLand";
import { Link as ReactLink, useParams } from "react-router-dom";
import { useState } from "react";
import ProfilePic from '../assets/images/profile.png'
import ProfileMain from '../assets/images/profileMain.png'
import Pic from '../assets/images/pic.png'
import Env from '../assets/images/env.png'
import Land from '../assets/images/loc.png'
import FeedBox from "../components/Home/FeedBox";
import NftPic from '../assets/images/shop.png'

export default function Profile() {

    const params = useParams();
    const buttonCss = {
        bgColor: 'transparent',
    }

    const [selectedBtn, setSelectedBtn] = useState('All');
    const [feed, setFeed] = useState([
        {
            id: 1,
            profile: ProfilePic,
            name: 'John Doe',
            time: '12 mins ago',
            caption: 'Check our my NFT, Complete colection available on bitly.en/4jhi5',
            image: Pic,
            category: 'Lands',
        },
        {
            id: 1,
            profile: ProfilePic,
            name: 'John Doe',
            time: '12 mins ago',
            caption: 'This is the description about the environment',
            image: Env,
            category: 'Environment',
        },
        {
            id: 1,
            profile: ProfilePic,
            name: 'John Doe',
            time: '12 mins ago',
            caption: 'This is the description about the Land',
            image: Land,
            category: 'NFT',
        },
    ]);

    const [myNft, setMyNft] = useState([
        NftPic, NftPic, NftPic, NftPic, NftPic, NftPic, NftPic, NftPic, NftPic,
    ])

    return (
        <Stack
            pb={12}
        >
            <Container maxW={'8xl'}>
                <Stack
                    boxShadow={'0px 0px 25px -14px #000'}
                    borderRadius={12}
                    px={'15px'}
                    py={'20px'}
                    mb={'40px'}
                    direction={{base: 'column', lg: 'row'}}
                    alignItems={'center'}
                    justifyContent={'space-betwen'}
                    w={'full'}
                >
                    <Box
                        flex={1}
                        display={{base: 'block', lg: 'flex'}}
                        alignItems={'center'}
                        gap={'5'}
                    >
                        <Image src={ProfileMain} alt="gallery Land" />
                        <Heading fontFamily={'Poppins'}>John Doe</Heading>
                    </Box>
                    {
                        params?.id === '2' &&
                        <Box>
                            <Button
                                fontFamily={"Poppins"}
                                bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                                color={'#fff'}
                                px={14}
                                _hover={{
                                    bg: 'primaryBlue.100',
                                }}
                            >Edit Profile</Button>
                        </Box>
                    }
                </Stack>
                <Stack
                    flexWrap={{ base: 'warp', lg: 'no-wrap' }}
                    direction={'row'}
                    justifyContent={'space-between'}
                    gap={6}
                >
                    <Stack
                        flex={1}
                        gap={6}
                        display={{ base: 'none', lg: 'none' }}
                    >
                        {/* My Land Component */}
                        <MyLand />
                    </Stack>
                    <Stack
                        flex={2}
                    >
                        {/* Feed Component */}
                        <Stack
                            boxShadow={'0px 0px 25px -14px #000'}
                            borderRadius={12}
                            px={'15px'}
                            py={'20px'}
                        >
                            <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                mb={'35px'}
                            >
                                <Heading fontSize={24} fontFamily={'Poppins'}>Feed</Heading>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={2}
                                    fontFamily={'Poppins'}
                                    flexWrap={{ base: 'wrap', lg: 'no-wrap' }}
                                    justifyContent={'flex-end'}
                                >
                                    <Button color={selectedBtn === 'All' ? '#573cc2' : '#c2c2c2'} sx={buttonCss} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: 'transparent' }} onClick={() => setSelectedBtn('All')}>All</Button>
                                    <Button color={selectedBtn === 'Lands' ? '#573cc2' : '#c2c2c2'} sx={buttonCss} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: 'transparent' }} onClick={() => setSelectedBtn('Lands')}>Lands</Button>
                                    <Button color={selectedBtn === 'Environment' ? '#573cc2' : '#c2c2c2'} sx={buttonCss} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: 'transparent' }} onClick={() => setSelectedBtn('Environment')}>Environment</Button>
                                    <Button color={selectedBtn === 'NFT' ? '#573cc2' : '#c2c2c2'} sx={buttonCss} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: 'transparent' }} onClick={() => setSelectedBtn('NFT')}>NFT</Button>
                                </Box>
                            </Stack>
                            <Stack>
                                {
                                    feed.length &&
                                    feed?.map((v, i) => <FeedBox key={i} {...v} />)
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        flex={1}
                        display={{ base: 'none', lg: 'none' }}
                    >
                        <Stack
                            boxShadow={'0px 0px 25px -14px #000'}
                            gap={6}
                            borderRadius={12}
                            px={'15px'}
                            py={'20px'}>

                            <Heading fontSize={24} fontFamily={'Poppins'}>My NFT</Heading>
                            <Stack
                                direction={'row'}
                                flexWrap={'wrap'}
                                spacing={'0'}
                                gap={1}
                            >
                                {
                                    myNft?.length &&
                                    myNft?.map((v, i) => <Image src={v} key={i} w={'32%'} />)
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
}