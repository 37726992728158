import {
    Stack,
    Container,
    FormControl,
    FormLabel,
    Input,
    Button,
    Heading,
    Box,
    Text,
    Image
} from "@chakra-ui/react";
import { useRef } from 'react'
import Image1 from '../assets/images/1.png'
import Image2 from '../assets/images/2.png'

export default function SignUp() {

    const formLabelCss = {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '16px',
        ml: '10px',
    }

    const inputCss = {
        px: 3,
        py: 6,
        bgColor: '#f3f3f3',
        borderRadius: '50px',
        placeholder: 'John Doe',
        border: 0,
    }

    const PictureRef = useRef(null);

    return (
        <Container maxW={'6xl'}>
            <Stack
                boxShadow={'0px 0px 25px -14px #000'}
                px={'30px'}
                py={'40px'}
                mx={{base: '0%', lg: '14%'}}
                mb={'80px'}
                borderRadius={12}
                bgColor={'#fff'}
            >
                <Heading
                    fontSize={'28px'}
                    fontFamily={'Poppins'}
                    color={'#000'}
                    mb={'30px'}
                >Sign Up</Heading>
                <Stack
                    direction={{base: 'column', lg: 'row'}}
                    flexWrap={'wrap'}
                    spacing={0}
                    justifyContent={'space-between'}
                >
                    <Box
                        w={{base: '100%', lg: '47%'}}
                        mb={'20px !important'}
                    >
                        <FormLabel sx={formLabelCss}>Full Name</FormLabel>
                        <Input
                            sx={inputCss}
                            _placeholder={{ fontWeight: '600' }}
                            _focusVisible={{ outline: 'none' }}
                            type='text'
                        />
                    </Box>
                    <Box
                        w={{base: '100%', lg: '47%'}}
                        mb={'20px !important'}
                    >
                        <FormLabel sx={formLabelCss}>Email</FormLabel>
                        <Input
                            sx={inputCss}
                            _placeholder={{ fontWeight: '600' }}
                            _focusVisible={{ outline: 'none' }}
                            type='email' />
                    </Box>
                    <Box
                        w={{base: '100%', lg: '47%'}}
                        mb={'20px !important'}
                    >
                        <FormLabel sx={formLabelCss}>Password</FormLabel>
                        <Input
                            sx={inputCss}
                            _placeholder={{ fontWeight: '600' }}
                            _focusVisible={{ outline: 'none' }}
                            type='password' />
                    </Box>
                    <Box
                        w={{base: '100%', lg: '47%'}}
                        mb={'20px !important'}
                    >
                        <FormLabel sx={formLabelCss}>Confirm Password</FormLabel>
                        <Input
                            sx={inputCss}
                            _placeholder={{ fontWeight: '600' }}
                            _focusVisible={{ outline: 'none' }}
                            type='password' />
                    </Box>
                    <Box
                        w={'100%'}
                        mb={'40px !important'}
                    >
                        <FormLabel sx={formLabelCss}>Upload Profile Picture</FormLabel>
                        <Input
                            ref={PictureRef}
                            display={'none'}
                            type='file'
                        />
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDir={'column'}
                            bgColor={'#f3f3f3'}
                            border={'4px dashed'}
                            borderRadius={'25px'}
                            borderColor={'primaryBlue.100'}
                            py={'40px'}
                        >
                            <Text
                                fontFamily={'Poppins'}
                                color={"primaryBlue.100"}
                                fontWeight={'600'}
                                fontSize={{base: '18', lg: '22'}}
                            >Drag and Drop your files here</Text>
                            <Text
                                fontFamily={'Poppins'}
                                color={"primaryBlue.100"}
                                fontWeight={'600'}
                                fontSize={'18'}
                                py={2}
                            >or</Text>
                            <Button
                                onClick={() => PictureRef.current.click()}
                                fontFamily={"Poppins"}
                                bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                                px={14}
                                _hover={{
                                    bg: 'primaryBlue.100',
                                    color: '#fff'
                                }}
                            >Browse Files</Button>
                        </Box>
                    </Box>
                    <Box
                        w={'100%'}
                        mb={'20px'}
                    >
                        <Button
                            fontFamily={"Poppins"}
                            bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                            _hover={{
                                bg: 'primaryBlue.100',
                                color: '#fff'
                            }}
                            w={'full'}
                            borderRadius={8}
                            py={6}
                        >Save</Button>
                    </Box>
                </Stack>
            </Stack>
            <Image src={Image1} position={'absolute'} top={0} right={0} zIndex={'-1'} />
            <Image src={Image2} position={'absolute'} bottom={0} left={0} zIndex={'-1'} />
        </Container>
    );
}