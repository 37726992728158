import React, { createContext } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import RouteSwitch from './RouteSwitch';
import NotFound from '../views/NotFound'
import Home from '../views/Home';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SignIn from '../views/SignIn';
import SignUp from '../views/SignUp';
import { Stack } from '@chakra-ui/react'
import UploadEnv from '../views/UploadEnv';
import SingleLand from '../views/SingleLand';
import Shop from '../views/Shop';
import LandRegistration from '../views/LandRegistration';
import Profile from '../views/Profile';
import Explore from '../views/Explore';
import Setting from '../views/Setting';

export const UserContext = createContext()

export default function AppRoute() {

    return (
        <div>
            <Router>
                <Header />
                <Stack marginTop={{base: '40px', lg: '140px'}}>
                    <RouteSwitch>
                        <Route exact path="/" element={<Home />}></Route>
                        <Route exact path="/sign-in" element={<SignIn />}></Route>
                        <Route exact path="/sign-up" element={<SignUp />}></Route>
                        <Route exact path="/upload-environment" element={<UploadEnv />}></Route>
                        <Route exact path="/single-land" element={<SingleLand />}></Route>
                        <Route exact path="/shop" element={<Shop />}></Route>
                        <Route exact path="/land-registration" element={<LandRegistration />}></Route>
                        <Route exact path="/profile/:id" element={<Profile />}></Route>
                        <Route exact path="/explore" element={<Explore />}></Route>
                        <Route exact path="/Setting" element={<Setting />}></Route>
                        <Route exact path="*" element={<NotFound />}></Route>
                    </RouteSwitch>
                </Stack>
                <Footer />
            </Router>
        </div>
    )
}