import { Stack, Box, FormLabel, Input, Text, Button, Icon, Heading, Image, Link } from '@chakra-ui/react'
import { useState, useRef } from 'react'
import { Link as ReactLink } from 'react-router-dom'
import { BiSearchAlt } from 'react-icons/bi'
import FullLoc from '../../assets/images/fullLoc.png'
import CustomFile from '../Customs/CustomFile.js'

export default function LandRegForm() {
    const formLabelCss = {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '18px',
        ml: '10px',
    }

    const inputCss = {
        px: 3,
        py: 6,
        bgColor: '#f3f3f3',
        borderRadius: '50px',
        placeholder: 'John Doe',
        border: 0,
    }

    const [landRefFields, setLandRefFields] = useState({
        map: FullLoc,
        name: '',
        address: '',
        gpsLocation: 'b-20, 144th Street, Manhattan NY',
        proof: ['Land Properties.jpeg', 'Land Properties.jpeg', 'Land Properties.jpeg'],
    });

    const PictureRef = useRef(null);

    return (
        <Stack>
            <Stack
                direction={{base: 'column', lg: 'row'}}
                alignItems={'center'}
                justifyContent={'space-between'}
                gap={4}
            >
                <Box mb={'20px !important'} w={{base: '100%', lg: '48%'}}>
                    <FormLabel sx={formLabelCss}>Land Name</FormLabel>
                    <Input
                        sx={inputCss}
                        _placeholder={{ fontWeight: '600' }}
                        _focusVisible={{ outline: 'none' }}
                        type='text'
                    />
                </Box>
                <Box mb={'20px !important'} w={{base: '100%', lg: '48%'}}>
                    <FormLabel sx={formLabelCss}>Full Address</FormLabel>
                    <Input
                        sx={inputCss}
                        _placeholder={{ fontWeight: '600' }}
                        _focusVisible={{ outline: 'none' }}
                        type='text'
                    />
                </Box>
            </Stack>
            <Stack
                direction={{base: 'column', lg: 'row'}}
                alignItems={'center'}
                justifyContent={'space-between'}
                gap={4}
            >
                <Box mb={{base: '0px !important', lg: '20px !important'}} w={{base: '100%', lg: '48%'}}>
                    <FormLabel sx={formLabelCss}>Select Pin Point Location</FormLabel>
                </Box>
                <Box
                    mb={'20px !important'}
                    w={{base: '100%', lg: '48%'}}
                    px={3}
                    bgColor={'#f3f3f3'}
                    borderRadius={'50px'}
                    display={'flex'}
                    alignItems={'center'}
                >
                    <Icon as={BiSearchAlt} fontSize={'26'} />
                    <Input
                        placeholder={'Search'}
                        border={0}
                        _placeholder={{ fontWeight: '600' }}
                        _focusVisible={{ outline: 'none' }}
                    />
                </Box>
            </Stack>
            <Stack>
                <Image
                    src={landRefFields?.map}
                    alt="Gallery Land"
                    mb={'20px !important'}
                />
                <Stack
                    direction={{base: 'column', lg: 'row'}}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    gap={4}
                >
                    <Box mb={'20px !important'} w={{base: '100%', lg: '48%'}}>
                        <FormLabel sx={formLabelCss}>GPS Location</FormLabel>
                        <Input
                            sx={inputCss}
                            _placeholder={{ fontWeight: '600' }}
                            _focusVisible={{ outline: 'none' }}
                            type='text'
                            value={landRefFields?.gpsLocation}
                            onChange={(e) => setLandRefFields({
                                ...landRefFields,
                                gpsLocation: e.target.value
                            })}
                        />
                    </Box>
                    <Box mb={'20px !important'} w={{base: '100%', lg: '48%'}}>
                        <FormLabel sx={formLabelCss}>Full Address</FormLabel>
                        <Input
                            sx={inputCss}
                            _placeholder={{ fontWeight: '600' }}
                            _focusVisible={{ outline: 'none' }}
                            type='text'
                        />
                    </Box>
                </Stack>
                <Box>
                    <FormLabel sx={formLabelCss}>Proof of OwnerShip</FormLabel>
                    <Box
                        w={'100%'}
                        mb={'40px !important'}
                    >
                        <Input
                            ref={PictureRef}
                            display={'none'}
                            type='file'
                        />
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDir={'column'}
                            bgColor={'#f3f3f3'}
                            border={'4px dashed'}
                            borderRadius={'25px'}
                            borderColor={'primaryBlue.100'}
                            py={'40px'}
                        >
                            <Text
                                fontFamily={'Poppins'}
                                color={"primaryBlue.100"}
                                fontWeight={'600'}
                                fontSize={{base: '18', lg: '22'}}
                            >Drag and Drop your files here</Text>
                            <Text
                                fontFamily={'Poppins'}
                                color={"primaryBlue.100"}
                                fontWeight={'600'}
                                fontSize={'18'}
                                py={2}
                            >or</Text>
                            <Button
                                onClick={() => PictureRef.current.click()}
                                fontFamily={"Poppins"}
                                bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                                px={14}
                                _hover={{
                                    bg: 'primaryBlue.100',
                                    color: '#fff'
                                }}
                            >Browse Files</Button>
                        </Box>
                    </Box>
                    <Stack
                        direction={'row'}
                        flexWrap={'wrap'}
                        justifyContent={'space-between'}
                        spacing={0}
                    >
                        {
                            landRefFields?.proof.length &&
                            landRefFields?.proof.map((v, i) => <CustomFile key={i} name={v} />)
                        }
                    </Stack>
                </Box>
                <Box>
                    <FormLabel sx={formLabelCss}>Upload Pictures of your Property</FormLabel>
                    <Box
                        w={'100%'}
                        mb={'40px !important'}
                    >
                        <Input
                            ref={PictureRef}
                            display={'none'}
                            type='file'
                        />
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDir={'column'}
                            bgColor={'#f3f3f3'}
                            border={'4px dashed'}
                            borderRadius={'25px'}
                            borderColor={'primaryBlue.100'}
                            py={'40px'}
                        >
                            <Text
                                fontFamily={'Poppins'}
                                color={"primaryBlue.100"}
                                fontWeight={'600'}
                                fontSize={{base: '18', lg: '22'}}
                            >Drag and Drop your files here</Text>
                            <Text
                                fontFamily={'Poppins'}
                                color={"primaryBlue.100"}
                                fontWeight={'600'}
                                fontSize={'18'}
                                py={2}
                            >or</Text>
                            <Button
                                onClick={() => PictureRef.current.click()}
                                fontFamily={"Poppins"}
                                bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                                px={14}
                                _hover={{
                                    bg: 'primaryBlue.100',
                                    color: '#fff'
                                }}
                            >Browse Files</Button>
                        </Box>
                    </Box>
                    <Stack
                        direction={'row'}
                        flexWrap={'wrap'}
                        justifyContent={'space-between'}
                        spacing={0}
                    >
                        {
                            landRefFields?.proof.length &&
                            landRefFields?.proof.map((v, i) => <CustomFile key={i} name={v} />)
                        }
                    </Stack>
                </Box>
            </Stack>
            <Box
                w={'100%'}
                mb={'20px'}
            >
                <Button
                    fontFamily={"Poppins"}
                    bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                    _hover={{
                        bg: 'primaryBlue.100',
                        color: '#fff'
                    }}
                    w={'full'}
                    borderRadius={8}
                    py={6}
                >Submit</Button>
            </Box>
        </Stack>
    )
}
