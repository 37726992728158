import { Heading, Stack, Link, Box, Text, Image } from "@chakra-ui/react";
import { Link as ReactLink } from 'react-router-dom'
import Map from '../../assets/images/map.png'
import { useState } from 'react'

export default function MyLand() {

    const [myLandList, setMyLandList] = useState([
        {
            image: Map,
            title: 'Land 1',
            location: 'Paris, France'
        },
        {
            image: Map,
            title: 'Land 2',
            location: 'Sydney, Aus'
        },
        {
            image: Map,
            title: 'Land 3',
            location: 'Sydney, Aus'
        },
    ]);

    return (
        <Stack
            boxShadow={'0px 0px 25px -14px #000'}
            borderRadius={12}
            py={'20px'}
            px={'15px'}
        >
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={"space-between"}
                fontFamily={'Poppins'}
                mb={'20px'}
            >
                <Heading
                    fontSize={'24px'}
                    fontWeight={'700'}
                    fontFamily={'Poppins'}
                >
                    My Land</Heading>
                <Link to="/land-registration" as={ReactLink} color={"#6249c6"} _hover={{ textDecoration: 'none' }}>Register Land</Link>
            </Stack>
            <Stack>
                {
                    myLandList.length &&
                    myLandList?.map((v, i) => {
                        return (
                            <Link
                                key={i}
                                as={ReactLink}
                                to="/single-land"
                                _hover={{ textDecoration: 'none' }}
                            >
                                <Box
                                    display={'flex'}
                                    marginBottom={'10px !important'}
                                    alignItems={'center'}
                                    boxShadow={'0px 0px 20px -14px #000'}
                                    borderRadius={12}
                                >
                                    <Image src={v?.image} alt="loc image" marginRight={'20px'} flex={1} />
                                    <Box>
                                        <Heading fontSize={'22px'} fontFamily={'Poppins'} flex={1}>{v?.title}</Heading>
                                        <Text fontSize={'18px'} color={'#8f8f8f'} >{v?.location}</Text>
                                    </Box>
                                    <Text flex={2} color={"#6249c6"} textAlign={'right'} pr={4} pt={3} alignSelf={'flex-start'}>Owned</Text>
                                </Box>
                            </Link>
                        )
                    })
                }
            </Stack>
        </Stack>
    );
}