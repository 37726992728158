import { Box, Container, Heading, Image, Stack, Text, Link, Button } from "@chakra-ui/react";
import { useState } from "react";
import ShopPic from '../assets/images/pic.png'
import ExploreCard from '../components/Explore/ExploreCard.js'
import UserPic from '../assets/images/profile.png'

export default function Explore() {

    const [selectedBtn, setSelectedBtn] = useState('All');
    const [exploreList, setExploreList] = useState([
        {
            userPic: UserPic,
            name: 'John Doe',
            time: '12 mins ago',
            nftPic: ShopPic,
            caption: 'These are the NFTs which are being sold by the galleryland.',
        },
        {
            userPic: UserPic,
            name: 'John Doe',
            time: '12 mins ago',
            nftPic: ShopPic,
            caption: 'These are the NFTs which are being sold by the galleryland.',
        },
        {
            userPic: UserPic,
            name: 'John Doe',
            time: '12 mins ago',
            nftPic: ShopPic,
            caption: 'These are the NFTs which are being sold by the galleryland.',
        },
        {
            userPic: UserPic,
            name: 'John Doe',
            time: '12 mins ago',
            nftPic: ShopPic,
            caption: 'These are the NFTs which are being sold by the galleryland.',
        },
        {
            userPic: UserPic,
            name: 'John Doe',
            time: '12 mins ago',
            nftPic: ShopPic,
            caption: 'These are the NFTs which are being sold by the galleryland.',
        },
        {
            userPic: UserPic,
            name: 'John Doe',
            time: '12 mins ago',
            nftPic: ShopPic,
            caption: 'These are the NFTs which are being sold by the galleryland.',
        },
        {
            userPic: UserPic,
            name: 'John Doe',
            time: '12 mins ago',
            nftPic: ShopPic,
            caption: 'These are the NFTs which are being sold by the galleryland.',
        },
        {
            userPic: UserPic,
            name: 'John Doe',
            time: '12 mins ago',
            nftPic: ShopPic,
            caption: 'These are the NFTs which are being sold by the galleryland.',
        },
        {
            userPic: UserPic,
            name: 'John Doe',
            time: '12 mins ago',
            nftPic: ShopPic,
            caption: 'These are the NFTs which are being sold by the galleryland.',
        },
    ]);

    return (
        <Stack
            pb={12}
        >
            <Container maxW={'8xl'}>
                <Stack
                    flexWrap={{ base: 'warp', lg: 'no-wrap' }}
                    direction={'row'}
                    justifyContent={'space-between'}
                    gap={6}
                    w={'full'}
                >
                    <Stack
                        boxShadow={'0px 0px 25px -14px #000'}
                        borderRadius={12}
                        w={'full'}
                        px={'15px'}
                        py={'20px'}
                    >
                        <Stack
                            direction={{ base: 'column', lg: 'row' }}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            mb={'25px'}
                            pb={'25px'}
                            borderBottom={'2px solid #adadad'}
                        >
                            <Heading fontSize={38} fontFamily={'Poppins'}>Explore</Heading>
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                gap={2}
                                fontFamily={'Poppins'}
                                flexWrap={{ base: 'wrap', lg: 'no-wrap' }}
                            >
                                <Button bgGradient={selectedBtn === 'All' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'All' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('All')}>All</Button>
                                <Button bgGradient={selectedBtn === 'Lands' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'Lands' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('Lands')}>Lands</Button>
                                <Button bgGradient={selectedBtn === 'Environment' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'Environment' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('Environment')}>Environment</Button>
                                <Button bgGradient={selectedBtn === 'NFT' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'NFT' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('NFT')}>NFT</Button>
                            </Box>
                        </Stack>
                        <Stack
                            spacing={0}
                            direction={'row'}
                            alignItems={'center'}
                            gap={6}
                            flexWrap={'wrap'}
                        >
                            {
                                exploreList.length &&
                                exploreList.map((v, i) => <ExploreCard key={i} {...v} />)
                            }
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
}