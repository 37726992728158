import { Box, Container, Heading, Image, Stack, Text, Link, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure } from "@chakra-ui/react";
import MyLand from "../components/Home/MyLand";
import Collections from "../components/Home/Collections";
import Gimage from '../assets/images/g.png'
import DiscoverNft from "../components/Home/DiscoverNft";
import { useState, useEffect } from "react";
import Profile from '../assets/images/profile.png'
import FeedBox from "../components/Home/FeedBox";
import { useNavigate } from 'react-router-dom'

export default function Home() {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();

  const buttonCss = {
    bgColor: 'transparent',
  }

  const [selectedBtn, setSelectedBtn] = useState('All');
  const [feed, setFeed] = useState([
    {
      id: 1,
      profile: Profile,
      name: 'John Doe',
      time: '12 mins ago',
      caption: 'Check our my NFT, Complete colection available on bitly.en/4jhi5',
      category: 'Lands',
      scatchFabUrl: 'https://sketchfab.com/models/92d36829b8ab483fa39bf44289f7b089/embed'
    },
    {
      id: 2,
      profile: Profile,
      name: 'John Doe',
      time: '12 mins ago',
      caption: 'This is the description about the environment',
      category: 'Environment',
      scatchFabUrl: 'https://sketchfab.com/models/92d36829b8ab483fa39bf44289f7b089/embed'
    },
    {
      id: 1,
      profile: Profile,
      name: 'John Doe',
      time: '12 mins ago',
      caption: 'This is the description about the Land',
      category: 'NFT',
      scatchFabUrl: 'https://sketchfab.com/models/92d36829b8ab483fa39bf44289f7b089/embed'
    },
  ]);
  const [walletInfo, setWalletInfo] = useState(null);

  const connectWallet = () => {
    if (window.ethereum) {
      window.ethereum.request({
        method: 'eth_requestAccounts'
      }).then(result => {
        setWalletInfo(result[0])
      })
    } else {
      onOpen();
    }
  };

  const toMetaMask = () => {
    window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn', '_blank');
    onClose();
  }

  useEffect(() => {
    console.log(walletInfo)
  }, [walletInfo])

  return (
    <Stack
      pb={12}
    >
      <Container maxW={{ base: '100%', lg: '8xl' }}>
        <Stack
          flexWrap={{ base: 'warp', lg: 'no-wrap' }}
          direction={{ base: 'column', lg: 'row' }}
          justifyContent={'space-between'}
          gap={6}
        >
          <Stack
            flex={1}
            gap={6}
            display={{ base: 'none', lg: 'initial' }}
          >
            {/* My Land Component */}
            <MyLand />
            {/* My Collection Component */}
            <Collections />
          </Stack>
          <Stack
            flex={2}
          >
            {/* Feed Component */}
            <Stack
              boxShadow={'0px 0px 25px -14px #000'}
              borderRadius={12}
              px={'15px'}
              py={'20px'}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                mb={'35px'}
              >
                <Heading fontSize={24} fontFamily={'Poppins'}>Feed</Heading>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={{ base: 0, lg: 2 }}
                  fontFamily={'Poppins'}
                  flexWrap={{ base: 'wrap', lg: 'no-wrap' }}
                  justifyContent={'flex-end'}
                >
                  <Button color={selectedBtn === 'All' ? '#573cc2' : '#c2c2c2'} sx={buttonCss} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: 'transparent' }} onClick={() => setSelectedBtn('All')}>All</Button>
                  <Button color={selectedBtn === 'Lands' ? '#573cc2' : '#c2c2c2'} sx={buttonCss} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: 'transparent' }} onClick={() => setSelectedBtn('Lands')}>Lands</Button>
                  <Button color={selectedBtn === 'Environment' ? '#573cc2' : '#c2c2c2'} sx={buttonCss} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: 'transparent' }} onClick={() => setSelectedBtn('Environment')}>Environment</Button>
                  <Button color={selectedBtn === 'NFT' ? '#573cc2' : '#c2c2c2'} sx={buttonCss} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: 'transparent' }} onClick={() => setSelectedBtn('NFT')}>NFT</Button>
                </Box>
              </Stack>
              <Stack>
                {
                  feed.length &&
                  feed?.map((v, i) => <FeedBox key={i} {...v} />)
                }
              </Stack>
            </Stack>
          </Stack>
          <Stack
            flex={1}
            display={{ base: 'none', lg: 'initial' }}
            gap={12}
          >
            <Button
              fontFamily={"Poppins"}
              bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
              _hover={{
                bg: 'primaryBlue.100',
                color: '#fff'
              }}
              w={'full'}
              borderRadius={8}
              py={6}
              color={'#fff'}
              mb={'10px !important'}
              onClick={() => navigate('/upload-environment')}
            >
              Upload Environment
            </Button>

            {/* Coins Component */}
            {
              walletInfo ?
                <Box
                  boxShadow={'0px 0px 25px -14px #000'}
                  borderRadius={12}
                  padding={6}
                  position={'relative'}
                  // _before={{
                  //   content: '""',
                  //   position: 'absolute',
                  //   left: '105px',
                  //   top: '50px',
                  //   w: '4px',
                  //   h: '40px',
                  //   bgColor: '#c2c2c2'
                  // }}
                  mb={'20px !important'}
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Image src={Gimage} alt="gallery land logo" />
                    <Box>
                      <Text fontFamily={'Poppins'} fontSize={'18px'} fontWeight={'600'}>Coins</Text>
                      <Heading color={'#573cc2'} fontFamily={'Poppins'} fontStyle={'italic'} fontSize={'36px'}>3845550m</Heading>
                      <>
                        <Link onClick={connectWallet} _hover={{ textDecoration: 'none' }} fontSize={'18px'} color={'#573cc2'} fontWeight={'600'}>Buy More</Link>
                      </>
                    </Box>
                  </Box>
                </Box>
                :
                <Button
                  fontFamily={"Poppins"}
                  bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                  _hover={{
                    bg: 'primaryBlue.100',
                    color: '#fff'
                  }}
                  w={'full'}
                  borderRadius={8}
                  py={6}
                  color={'#fff'}
                  mb={'10px !important'}
                  onClick={() => connectWallet()}
                >
                  Connect Wallet
                </Button>
            }
            {/* Discover NFTs component */}
            <DiscoverNft />
          </Stack>
        </Stack>
        <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Wallet not found!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                It seems to be like there is no wallet available on your browser, please install MetaMask and restart your browser to connect it with Gallery Land.
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={toMetaMask}>Get MetaMask</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Container>
    </Stack >
  );
}