import { Stack, Box, Button, Icon, Menu, MenuList, MenuItem, MenuButton, Image, Text, } from '@chakra-ui/react'
import { BsBell } from 'react-icons/bs'
import { AiFillCaretDown } from 'react-icons/ai'
import UserPic from '../../assets/images/profile.png'
import { useNavigate } from 'react-router-dom'

export default function LoginUser() {

    const navigate = useNavigate();

    return (
        <Stack
            direction={'row'}
        >
            <Box>
                <Menu>
                    <Button
                        as={MenuButton}
                        backgroundColor={'transparent'}
                        _hover={{ backgroundColor: 'transparent' }}
                        _active={{ backgroundColor: 'transparent' }}
                    >
                        <Icon as={BsBell} fontSize={26} />
                    </Button>
                    <MenuList>
                        <MenuItem>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                gap={5}
                            >
                                <Image src={UserPic} />
                                <Box>
                                    <Text fontFamily={'Poppins'} color={'#1a1a1a'} fontSize={15}>John added your artwork to his collection</Text>
                                    <Text fontFamily={'Poppins'} color={'#1a1a1a'} fontSize={13}>12 mins ago</Text>
                                </Box>
                            </Stack>
                        </MenuItem>
                        <MenuItem>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                gap={5}
                            >
                                <Image src={UserPic} />
                                <Box>
                                    <Text fontFamily={'Poppins'} color={'#1a1a1a'} fontSize={15}>John added your artwork to his collection</Text>
                                    <Text fontFamily={'Poppins'} color={'#1a1a1a'} fontSize={13}>12 mins ago</Text>
                                </Box>
                            </Stack>
                        </MenuItem>
                        <MenuItem>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                gap={5}
                            >
                                <Image src={UserPic} />
                                <Box>
                                    <Text fontFamily={'Poppins'} color={'#1a1a1a'} fontSize={15}>John added your artwork to his collection</Text>
                                    <Text fontFamily={'Poppins'} color={'#1a1a1a'} fontSize={13}>12 mins ago</Text>
                                </Box>
                            </Stack>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Box>
            <Box w={'170px'}>
                <Menu>
                    <Button
                        as={MenuButton}
                        backgroundColor={'transparent'}
                        _hover={{ backgroundColor: 'transparent' }}
                        _active={{ backgroundColor: 'transparent' }}
                    >
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                        >
                            <Image src={UserPic} />
                            <Text fontFamily={'poppins'}>John Doe</Text>
                            <Icon as={AiFillCaretDown} />
                        </Stack>
                    </Button>
                    <MenuList>
                        <MenuItem onClick={() => navigate('/profile/2')}>Profile</MenuItem>
                        <MenuItem onClick={() => navigate('/setting')}>Settings</MenuItem>
                        <MenuItem onClick={() => navigate('/sign-in')}>Logout</MenuItem>
                    </MenuList>
                </Menu>
            </Box>
        </Stack>
    )
}
